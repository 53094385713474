import React from "react";
import { AiOutlineDown } from "react-icons/ai";
import "./style.css";

const DownButton = ({ element }) => {
  const handleScroll = (e) => {
    e.preventDefault();
    const section = document.querySelector(`.${element}`);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="about-us-hero-downButton">
      <a href="#" class="scroll-down" address="true" onClick={handleScroll}>
        <AiOutlineDown className="scroll-down-arrow" />
      </a>
    </div>
  );
};

export default DownButton;
