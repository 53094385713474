import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { FaPlus, FaMinus } from "react-icons/fa";
import "./style.css";

const CustomCollapse = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  return (
    <div className="custom-collapse-container my-3">
      <div
        onClick={toggleCollapse}
        className={`custom-collapse-header ${open ? "open" : ""}`}
      >
        <h5 className="custom-collapse-title text-start m-0">{title}</h5>
        <div className="custom-collapse-icon">
          {open ? <FaMinus /> : <FaPlus />}
        </div>
      </div>
      <Collapse in={open}>
        <div className="custom-collapse-body">{children}</div>
      </Collapse>
    </div>
  );
};

export default CustomCollapse;
