import React from "react";
import "./styleEventCard.css";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EventCard = ({ dataEvents }) => {
  return (
    <>
      <div className="events-target-info">
        <div className="image-container-event">
          <img
            src={dataEvents.img} alt={dataEvents.title}
            className="target-image-event"
          />
        </div>
        <div className="info-container-Event" style={{ textAlign: 'center' }}>
        <h3 className="target-title">{dataEvents.title}</h3>
          <p className="target-address">
            {dataEvents.date}
          </p>
        </div>
      </div>
    </>
  );
};

// Componente EventSlider
const EventSlider = ({ dataEvent }) => {

  // Componente para la flecha derecha del slider
  function SampleNextArrow({ onClick }) {
    return (
      <div className="arrow-Event arrow-right-Event" onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  }

  // Componente para la flecha izquierda del slider
  function SamplePrevArrow({ onClick }) {
    return (
      <div className="arrow-Event arrow-left-Event" onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  }
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {dataEvent
      
      .sort(
        (a, b) =>
          new Date(b.date_start + "T00:00:00") - new Date(a.date_start + "T00:00:00")
      )
      
      .map((event, index) => (
        <div key={index} className="d-flex justify-content-center">
          <EventCard
            dataEvents={{
              img: event.logo,
              title: event.name,
              date: `${new Date(event.date_start + "T00:00:00").toLocaleDateString()} - ${new Date(event.date_finish + "T00:00:00").toLocaleDateString()}`,
            }}
          />
        </div>
      ))}
    </Slider>
  );
};

export default EventSlider;