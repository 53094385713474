import React, { useState } from "react";
import "./style.css";

const ProductCard = ({ name, text, image, color }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <div
        className="product-circle"
        style={{ backgroundColor: isHovered ? color : "#ffffff" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!isHovered ? (
          <img src={image} alt={name} className="product-circle-image" />
        ) : (
          <div className="product-circle-text">
            <span>{name}</span>
            <p>{text}</p>
          </div>
        )}
      </div>
      <div className="response-content">
        <div className="product-circle-responsive">
          <img src={image} alt={name} className="product-circle-image" />
        </div>
        <div className="responsive-text">
          <span>{name}</span>
          <p>{text}</p>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
