import { Navigate, useLocation } from "react-router-dom";
import ThankYou from "../../views/contact-us/contact-form/thankyou";

function ProtectedRoute() {
    const location = useLocation();
    const statusThanks = location.state?.statusThanks || 'false';

    return (
        statusThanks === 'true' ? <ThankYou /> : <Navigate to={'/'} replace />
    );
}

export default ProtectedRoute;