import React from "react";
import "./contact-form.css";
import NavBar from "../../../Components/navbar/navbar";
import Footer from "../../../Components/footer/footer";
import ScrollToTop from "../../../Components/scrollToTop/scrollToTop";
import { Link } from "react-router-dom";

const ThankYou = () => {
  return (
    <>
      <NavBar />
      <ScrollToTop />
      <div className="thankyou-hero">
        <h1>Gracias por contactarnos</h1>
        <h5>Tu mensaje ha sido enviado</h5>
        <div className="buttons-container mt-5">
        <Link to="/">REGRESAR AL INICIO</Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ThankYou;
