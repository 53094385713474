import Footer from "../../Components/footer/footer";
import NavBar from "../../Components/navbar/navbar";
import SquareCard from "../../Components/square-card/square-card";
import HeaderHome from "./header/header-home";
import "./home-main.css";
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import { useRef } from "react";

// Components
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";
import ProductCard from "./product-card/ProductCard";
import {Helmet} from "react-helmet";

// Images
import imgRules from "../../assets/imgHome/squid-cards/rules.png";
import imgHistory from "../../assets/imgHome/squid-cards/history.png";
import imgJudges from "../../assets/imgHome/squid-cards/judges.png";
import LuisAthlete from "../../assets/imgHome/athletes/luis.png";
import AleAthlete from "../../assets/imgHome/athletes/alejandra.png";
import LaloAthlete from "../../assets/imgHome/athletes/lalo.png";
import Commodity1 from "../../assets/imgHome/commodity/commodity1.png";
import Commodity2 from "../../assets/imgHome/commodity/commodity2.png";
import Commodity3 from "../../assets/imgHome/commodity/commodity3.png";
import NationalTeamSlider from "./national-team/national-team-slider";

function HomeMain() {
  const containerRefs = useRef({
    archery: null,
    aboutUs: null,
    product: null,
    athletes: null,
  });

  const athletesData = [
    {
      name: "Luis Alvarez",
      text: "Commodo minim dolore in sunt velit sunt irure esse. Id tempor dolore deserunt ut reprehenderit consequat magna minim. Occaecat pariatur qui mollit aliqua cillum tempor est ad minim quis",
      place: "Mexicali",
      color: "#12ad2b",
      imag: LuisAthlete,
    },
    {
      name: "Alejandra Valencia",
      text: "Commodo minim dolore in sunt velit sunt irure esse. Id tempor dolore deserunt ut reprehenderit consequat magna minim. Occaecat pariatur qui mollit aliqua cillum tempor est ad minim quis",
      place: "Hermosillo",
      color: "#ffc726",
      imag: AleAthlete,
    },
    {
      name: "Eduardo Vélez",
      text: "Commodo minim dolore in sunt velit sunt irure esse. Id tempor dolore deserunt ut reprehenderit consequat magna minim. Occaecat pariatur qui mollit aliqua cillum tempor est ad minim quis",
      place: "Jalisco",
      color: "#00b5e6",
      imag: LaloAthlete,
    },
  ];
  const products = [
    {
      name: "Visera WA Mex",
      text: "Excelente ventilación y protección solar. Entrena con comodidad y mantente fresco. Ideal para tus sesiones de entrenamiento al aire libre.",
      color: "#ffc726",
      imag: Commodity1,
    },
    {
      name: "Gorro WA Mex",
      text: "¡Dale un toque fresco y moderno a tus entrenamientos y competencias! Agregar un toque casual a tu look diario",
      color: "#12ad2b",
      imag: Commodity2,
    },
    {
      name: "Gorra WA Mex",
      text: "¡Mantén el estilo dentro y fuera del campo! Perfecta para protegerte del sol durante tus entrenamientos o simplemente para complementar tu look casual.",
      color: "#00b5e6",
      imag: Commodity3,
    },
  ];

  const scrollLeft = (section) => {
    const ref = containerRefs.current[section];
    if (ref) {
      ref.scrollLeft -= 300;
    }
  };

  const scrollRight = (section) => {
    const ref = containerRefs.current[section];
    if (ref) {
      ref.scrollLeft += 300;
    }
  };

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>WA México | Tiro con Arco México</title>
        <meta name="description" content="Federación de Tiro con Arco en México: Promovemos, organizamos y desarrollo el tiro con arco en el país." />
      </Helmet>
      <NavBar />
      <HeaderHome />

      <div className="title-with-description ourHome" id="ourHome">
        <h3>Tiro con Arco en México</h3>
        <p>
          El tiro con arco en México ha experimentado un notable crecimiento en
          los últimos años, convirtiéndose en un deporte en auge gracias a las
          brillantes actuaciones de nuestros atletas en el escenario
          internacional y a su creciente accesibilidad en todo el país. Además,
          al albergar competencias internacionales de élite, México ha
          consolidado su posición como un referente en el mundo del tiro con
          arco, cautivando al público y fortaleciendo la base de arqueros
          nacionales.
        </p>
      </div>

      <div className="slider-archery-home">
        <i onClick={() => scrollLeft("archery")}>
          <BiSolidChevronLeft />
        </i>
        <div
          className="tagets-container"
          ref={(el) => (containerRefs.current.archery = el)}
        >
          <SquareCard img={imgRules} text={"Arqueros"} link="/athletes" />
          <SquareCard img={imgHistory} text={"Competencias"} link="/events" />
          <SquareCard img={imgJudges} text={"Campos"} link="/fields" />
        </div>
        <i onClick={() => scrollRight("archery")}>
          <BiSolidChevronRight />
        </i>
      </div>

      <div className="product-section">
        <h3>Mercancía</h3>
        <p>
        Únete a nuestra causa adquiriendo la nueva línea de merchandising oficial de New Era y World Archery México. Cada producto que compres no solo te permitirá lucir increíble, sino que también contribuirá directamente al desarrollo del tiro con arco en México.
        </p>
        <div className="product-section-slider">
          <i onClick={() => scrollLeft("product")}>
            <BiSolidChevronLeft />
          </i>
          <div
            className="tagets-container"
            ref={(el) => (containerRefs.current.product = el)}
          >
            {products.map((item, i) => (
              <div key={i}>
                <ProductCard
                  name={item.name}
                  text={item.text}
                  image={item.imag}
                  color={item.color}
                />
              </div>
            ))}
          </div>
          <i onClick={() => scrollRight("product")}>
            <BiSolidChevronRight />
          </i>
        </div>
      </div>

      <div className="athletes-section-home">
        <h4>Selección Nacional</h4>
        <h3>Final de Copa - Tlaxcala 2024</h3>
        <NationalTeamSlider />
      </div>

      {/* <div className="donations-and-events-container">
        <div className="donations">
          <h4>Donaciones</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur. Nibh lectus volutpat morbi
            sit fusce sed augue pretium sit. Sodales ullamcorper egestas
            senectus viverra nisi feugiat ipsum aliquet. Tempus lorem urna
            facilisis lacus id amet volutpat scelerisque lacus. Molestie turpis
            sit malesuada auctor feugiat
          </p>
          <button className="btn-donation">
            Quiero donar
            <i>
              <BiSolidChevronRight />
            </i>
          </button>
        </div>

        <div className="events">
          <h4>Encuentra nuestro próximo evento</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur. Nibh lectus volutpat morbi
            sit fusce sed augue pretium sit. Sodales ullamcorper egestas
            senectus viverra nisi feugiat ipsum aliquet. Tempus lorem urna
            facilisis lacus id amet volutpat scelerisque lacus. Molestie turpis
            sit malesuada auctor feugiat
          </p>
          <form>
            <select>
              <option value="0">Selecciona una ciudad</option>
            </select>

            <button type="submit">Buscar</button>
          </form>
        </div>
      </div> */}

      <Footer />
    </>
  );
}

export default HomeMain;
