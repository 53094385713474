import './fields-target-info.css';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function FieldsTargetInfo( {dataField} ) {

    const navigate = useNavigate();

    const normalizeTitle = (name) => {
        return name.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
    };    

    const handleClick = () => {
        navigate(`/fields/${normalizeTitle(dataField.name)}`);
    };

    return(
        <div className="fields-target-info" onClick={handleClick}>
            <div className="image-container">
                <img src={`https://worldarcherymexico.com/administrador/assets/images/photos_fields/${dataField.foto_portada}`} alt={dataField.name} className="target-image" />
                <div className="title-overlay">
                    <h3 className="target-title">{dataField.name}</h3>
                </div>
            </div>
            <div className="info-container">
                <p className="target-address">
                    <FaMapMarkerAlt className="location-icon" />
                    {dataField.address}
                </p>
            </div>
        </div>
    );
}

export default FieldsTargetInfo;