import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./post-detail.css";
import archeryIcon from "../../assets/archery_icon_small.png"
import axios from "axios";
import NavBar from "../navbar/navbar";
import Footer from "../footer/footer";

function PostDetail() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
            `${process.env.REACT_APP_URL_BACK}/0a2b15b1a0d8d09e86d4b42d2012a091/notices_detail/${slug}`
            );
        setPost(response.data.data);
      } catch (err) {
        setError("Error al cargar el post.");
      }
    };

    fetchPost();
  }, [slug]);

  if (error) return <p>{error}</p>;
  if (!post) {
    return <div className="loading-container d-flex justify-content-center align-items-center">
      <div> Cargando...
        <img className="archery-icon" src={archeryIcon}/> 
      </div>
    </div>;
    }

  
  
  return (
    <>
      <NavBar/>
      <div className="post-header-container"></div>
      <div className="container">
        <div className="px-4">
          <div className="row">
            <span className="py-4 post-news">Noticias</span>
            <h1>{post.title}</h1>
            <p className="post-description">{post.description}</p>
          </div>
          <div className="row d-flex justify-content-center">
            <img src={post.image} alt={post.title} className="post-img"/>
          </div>
          <div className="row">
            <p className="post-body" dangerouslySetInnerHTML={{ __html: post.body}}></p>
          </div>
        </div>
      </div>
      <Footer/>
    </> 
  );
}

export default PostDetail;