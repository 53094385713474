import React from "react";
import { Helmet } from "react-helmet";
import { IoIosArrowDown } from "react-icons/io";
import "./executiveBoard.css";
import NavBar from "../../Components/navbar/navbar";
import Footer from "../../Components/footer/footer";

//Importar Imagenes
import perfil from "../../assets/imgExecutiveBoard/perfil_executive_board.jpg";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";


function ExecutiveBoard() {
  const handleScroll = (e) => {
    e.preventDefault();
    const section = document.querySelector(".councilMembers");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const cardsData = [
    {
      title: "Gabriel Ramos",
      parrafo: "Presidente",
      text: "Lorem ipsum dolor sit amet consectetur. ",
      image: perfil,
    },
    {
      title: "Luis Manuel López",
      parrafo: "Vicepresidente",
      text: "Lorem ipsum dolor sit amet consectetur. ",
      image: perfil,
    },
    {
      title: "Luis Eduardo Vélez",
      parrafo: "Secretario",
      text: "Lorem ipsum dolor sit amet consectetur. ",
      image: perfil,
    },
    {
      title: "Carol Enrique Gamboa",
      parrafo: "Tesorero",
      text: "Lorem ipsum dolor sit amet consectetur. ",
      image: perfil,
    },
    {
      title: "Ilse Rivera Leyva",
      parrafo: "Vocal",
      text: "Lorem ipsum dolor sit amet consectetur. ",
      image: perfil,
    },
    {
      title: "Paola Palomeque",
      parrafo: "Vocal Deportista",
      text: "Lorem ipsum dolor sit amet consectetur. ",
      image: perfil,
    },
    {
      title: "Luis Rodrigo López",
      parrafo: "Representante Jurídico",
      text: "Lorem ipsum dolor sit amet consectetur. ",
      image: perfil,
    },
  ];

  const CardComponent = ({ title, text, image, parrafo }) => (
    <div className="card-container">
      <div className="cards-board">
        <img src={image} alt={""} />
        <div className="cards-body" style={{ backgroundColor: "#00209F" }}>
          <div className="container">
            <h5 className="card-title">{title}</h5>
            <p className="card-parrafo">{parrafo}</p>
            {/* <p className="card-text">{text}</p> */}
          </div>
        </div>
      </div>
    </div>  
  );

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>Consejo Directivo | WA México</title>
        <meta name="description" content="Conoce al Consejo Directivo de la Federación; equipo comprometido con el desarrollo y éxito de World Archery México." />
      </Helmet>
      <NavBar />
      <section className="contExecutiveBoard">
        <div className="executive-title">
          <h1>Consejo Directivo</h1>
        </div>

        <div className="contentParrafoExecutive">
          <p>
            Órgano responsable de supervisar la gestión diaria de la
            organización, asegura la transparencia en todas las operaciones y
            garantiza el cumplimiento de los estatutos.
          </p>
        </div>

        <a
          href="#"
          className="scroll-down"
          address="true"
          onClick={handleScroll}
        >
          <IoIosArrowDown className="scroll-down-arrow" />
        </a>
      </section>

      <section className="councilMembers" id="councilMembers">
        <div className="council_Members_title">
          <h2>Miembros del Consejo</h2>
        </div>

        <div className="content_parrafo_councilMembers">
          <div className="container">
            <p>
              El Consejo Directivo es un órgano colegiado compuesto por 7
              miembros, quienes son elegidos por la Asamblea para un período de
              cuatro años, con la posibilidad de reelección por un período
              adicional. Encargado de asegurar la vigilancia y el
              cumplimiento del estatuto de la Federación.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="card-slider">
          <div className="tagets-container">
            {cardsData.slice(0, 7).map((card, index) => (
              <CardComponent key={index} {...card} />
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ExecutiveBoard;
