import NavBar from "../../../Components/navbar/navbar";
import { Helmet } from "react-helmet";
import { IoIosArrowDown } from "react-icons/io";
import './contact-us-main.css';
import { FaMapMarkerAlt, FaMapMarkedAlt, FaPhone, FaEnvelope, FaClock } from "react-icons/fa";
import ContactForm from "../contact-form/contact-form";
import Footer from "../../../Components/footer/footer";
import ScrollToTop from "../../../Components/scrollToTop/scrollToTop";

function ContactUs() {

  const handleScroll = (e) => {
    e.preventDefault();
    const section = document.querySelector(".title-contact");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return(
    <>
      <ScrollToTop />
      <Helmet>
        <title>Contacto | WA México</title>
        <meta name="description" content="Contáctanos hoy mismo y estaremos encantados de ayudarte." />
      </Helmet>
      <NavBar />
      <section className="contact-us-header">
        <div className="coaches-title">
          <h1>Contacto</h1>
        </div>
        <div className="contentParrafoCoaches">
          <p>
            Nuestro equipo está listo para brindarte la asistencia que necesitas. Visítanos en nuestras oficinas o comunícate con nosotros a través del formulario de contacto.
          </p>
        </div>
        <div>
          <a href="#" className="scroll-down" address="true" onClick={handleScroll}>
            <IoIosArrowDown className="scroll-down-arrow" />
          </a>
        </div>
      </section>

      <section className="container">
        <div className="title-contact">
          <h4>Contáctanos</h4>
        </div>

        <div className="contact-container">
          <div className="info-contact">

            <div className="info-text">
              <li><FaMapMarkerAlt /></li>
              <p>Calle Alfonso Reyes #68 Int 3 Col. Hipódromo Condesa Alcaldía Cuauhtémoc, 06170</p>
            </div>
            <div className="info-text">
              <li><FaMapMarkedAlt /></li>
              <p>Ciudad de México</p>
            </div>
            <div className="info-text">
              <li><FaPhone /></li>
              <p className="btn-tel-dk">55 5038 9000</p>
              <a 
                href="tel:5512345678"
                target="_blank"
                rel="noreferrer"
                className="btn-tel-mb"
              >
                55 5038 9000
              </a>
            </div>
            <div className="info-text">
              <li><FaEnvelope /></li>
              <p>info@worldarcherymex.com</p>
            </div>
            <div className="info-text">
              <li><FaClock /></li>
              <div className="schedules">
                <p>Horarios</p>
                <p>Lunes a Viernes 9:00 am a 6:00 PM</p>
              </div>
            </div>

            <div className="maps-container">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15052.186412045581!2d-99.1796415!3d19.4103923!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff004ff9d577%3A0x841f0da764f5c45c!2sWorld%20Archery%20Mexico!5e0!3m2!1ses-419!2smx!4v1724688496040!5m2!1ses-419!2smx" width="100%" height="100%" style={{border: '0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

          </div>

          <div className="form-container">
            <ContactForm />
          </div>
        </div>
      </section>

      <Footer />

    </>
  )

}

export default ContactUs;