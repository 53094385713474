import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../../Components/navbar/navbar";
import Footer from "../../Components/footer/footer";
import "./fields.css";
import { IoIosArrowDown } from "react-icons/io";
import FieldsTargetInfo from "../../Components/fields-target-info/fields-target-info";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaAngleLeft, FaAngleRight, FaChevronDown } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";


function Fields() {
  const [viewFields, setViewFields] = useState(9);
  const [searchName, setSearchName] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const [fields, setFields] = useState([]);

  const handleScroll = (e) => {
    e.preventDefault();
    const section = document.querySelector(".ourFields");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handdleShowMoreFields = () => {
    setViewFields(viewFields + 9);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL_BACK}/0a2b15b1a0d8d09e86d4b42d2012a091/fields`
      )
      .then((response) => {
        if (response.data.code === 1) {
          setFields(response.data.data); // Guardar los datos en el estado
        } else {
          console.error("Error al obtener los datos:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error en la solicitud:", error);
      });
  }, []);

  const filteredFields = fields.filter((field) => {
    return (
      (searchName === "" ||
        `${field.name}`.toLowerCase().includes(searchName.toLowerCase())) &&
      (selectedState === "" || field.state === selectedState)
    );
  });

  // Configuración del slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>Campos | WA México</title>
        <meta name="description" content="Encuentra los diversos campos y escuelas donde se practica el tiro con arco en México." />
      </Helmet>
      <NavBar />
      <section className="fields-hero">
        <div className="fields-title">
          <h1>Campos</h1>
        </div>
        <div className="contentParrafoFields">
          <p>
            Desde escuelas de iniciación hasta centros de alto rendimiento, aquí
            encontrarás los diversos campos donde se practica el tiro con arco
            en México.
          </p>
        </div>
        <div>
          <a
            href="#"
            className="scroll-down"
            address="true"
            onClick={handleScroll}
          >
            <IoIosArrowDown className="scroll-down-arrow" />
          </a>
        </div>
      </section>

      <section className="ourFields" id="ourFields">
        <div className="d-none d-md-block">
          <div className="our_fields_title">
            <h2>Búsquedas populares</h2>
          </div>

          {/* Slider */}
          <div className="container mt-5">
            <Slider {...settings}>
              {fields.map((field, index) => (
                <div key={index} className="d-flex justify-content-center">
                  <FieldsTargetInfo dataField={field} />
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/* Formulario de filtros */}
        <div className="container custom-container mt-5 mb-5">
          <div className="row">
            <div className="col-md-6 col-12 mb-3 d-flex justify-content-center">
              <div className="search-container">
                <FaMagnifyingGlass className="search-icon" />
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Buscar por nombre"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </div>
            </div>

            <div className="col-md-6 col-12 mb-3 d-flex justify-content-center">
              <div className="select-container">
                <select
                  className="form-control select-filter"
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <option value="">Estado: Todos</option>
                  <option value="Aguascalientes">Aguascalientes</option>
                  <option value="Baja California">Baja California</option>
                  <option value="Baja California Sur">
                    Baja California Sur
                  </option>
                  <option value="Campeche">Campeche</option>
                  <option value="Chiapas">Chiapas</option>
                  <option value="Chihuahua">Chihuahua</option>
                  <option value="Ciudad de México">Ciudad de México</option>
                  <option value="Coahuila">Coahuila</option>
                  <option value="Colima">Colima</option>
                  <option value="Durango">Durango</option>
                  <option value="Estado de México">Estado de México</option>
                  <option value="Guanajuato">Guanajuato</option>
                  <option value="Guerrero">Guerrero</option>
                  <option value="Hidalgo">Hidalgo</option>
                  <option value="Jalisco">Jalisco</option>
                  <option value="Michoacán">Michoacán</option>
                  <option value="Morelos">Morelos</option>
                  <option value="Nayarit">Nayarit</option>
                  <option value="Nuevo León">Nuevo León</option>
                  <option value="Oaxaca">Oaxaca</option>
                  <option value="Puebla">Puebla</option>
                  <option value="Querétaro">Querétaro</option>
                  <option value="Quintana Roo">Quintana Roo</option>
                  <option value="San Luis Potosí">San Luis Potosí</option>
                  <option value="Sinaloa">Sinaloa</option>
                  <option value="Sonora">Sonora</option>
                  <option value="Tabasco">Tabasco</option>
                  <option value="Tamaulipas">Tamaulipas</option>
                  <option value="Tlaxcala">Tlaxcala</option>
                  <option value="Veracruz">Veracruz</option>
                  <option value="Yucatán">Yucatán</option>
                  <option value="Zacatecas">Zacatecas</option>
                  <option value="UNAM">UNAM</option>
                  <option value="IPN">IPN</option>
                </select>
                <FaChevronDown className="select-icon" />
              </div>
            </div>
          </div>
        </div>

        {/* Targets de campos */}
        <div className="container custom-container">
          <div className="row">
            {filteredFields.slice(0, viewFields).map((field, index) => (
              <div
                className="col-md-4 d-flex justify-content-center mb-5"
                key={index}
              >
                <FieldsTargetInfo dataField={field} />
              </div>
            ))}
          </div>
        </div>

        {filteredFields.length > viewFields && (
          <div className="show-more">
            <button onClick={handdleShowMoreFields}>Mostrar más</button>
          </div>
        )}
      </section>

      <Footer />
    </>
  );
}

// Componente para la flecha derecha del slider
function SampleNextArrow({ onClick }) {
  return (
    <div className="arrow arrow-right" onClick={onClick}>
      <FaAngleRight />
    </div>
  );
}

// Componente para la flecha izquierda del slider
function SamplePrevArrow({ onClick }) {
  return (
    <div className="arrow arrow-left" onClick={onClick}>
      <FaAngleLeft />
    </div>
  );
}

export default Fields;
