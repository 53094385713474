import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import NavBar from "../../Components/navbar/navbar";
import Footer from "../../Components/footer/footer";
import { IoIosArrowDown, IoMdSearch } from "react-icons/io";
import "./athletes.css";
import AthletesTargetInfo from "../../Components/athletes-target-info/athletes-target-info";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";
import axios from "axios";

function Athletes() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchClub, setSearchClub] = useState("");
  const [selectedAsociation, setSelectedAsociation] = useState("");
  const [selectedMode, setSelectedMode] = useState("");
  const [selectedState, setSelectedState] = useState("all");
  const [viewAthletes, setViewAthletes] = useState(12);
  const [dataAthletes, setDataAthletes] = useState([]);

  const handleScroll = (e) => {
    e.preventDefault();
    const section = document.querySelector(".ourAthletes");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handdleShowMoreAthletes = () => {
    setViewAthletes(viewAthletes + 12);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClubChange = (e) => {
    setSearchClub(e.target.value);
  };

  // const handleAsociationChange = (e) => {
  //   setSelectedAsociation(e.target.value);
  // };

  const handleModeChange = (e) => {
    setSelectedMode(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  useEffect(() => {
    getAthletes();
  }, []);

  const getAthletes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACK}/0a2b15b1a0d8d09e86d4b42d2012a091/athletes`
      );

      const filteredData = response.data.data.filter((person) =>
        person.id_roll.includes("Arquero")
      );

      setDataAthletes(filteredData);
    } catch (error) {
      console.log(error);
    }
  };

  const filteredAthletes = dataAthletes.filter(
    (athlete) =>
      `${athlete.name} ${athlete.last_name}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) &&
      (selectedAsociation === "" ||
        athlete.assoc_name === selectedAsociation) &&
      (selectedMode === "" || athlete.id_modality.includes(selectedMode)) &&
      (selectedState === "all" ||
        athlete.association_state === selectedState) &&
      `${athlete.club_name}`
        .toLocaleLowerCase()
        .includes(searchClub.toLocaleLowerCase())
  );

  const handleInputsClear = () => {
    setSearchTerm("");
    setSelectedState('all');
    setSearchClub("");
    setSelectedMode("");
  }

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>Arqueros | WA México</title>
        <meta name="description" content="Conoce a los arqueros afiliados a World Archery México." />
      </Helmet>
      <NavBar />
      <section className="contOurAthletes">
        <div className="athletes-title">
          <h1>Arqueros Mexicanos</h1>
        </div>

        <div className="contentParrafoAthletes">
          <p>
            Conoce los atletas de nuestro deporte, el motor principal de la
            Federación.
          </p>
        </div>

        <div>
          <a
            href="#"
            className="scroll-down"
            address="true"
            onClick={handleScroll}
          >
            <IoIosArrowDown className="scroll-down-arrow" />
          </a>
        </div>
      </section>

      <div className="container">
        <section className="ourAthletes" id="ourAthletes">
          <div className="our_Athletes_title">
            <h2>Búsqueda de atletas</h2>
          </div>
        </section>

        <div className="search-inputs-container mt-4">
          <div className="input-container">       
            <div className="input-icon">
              <IoMdSearch />
            </div>
            <input
              type="text"
              className="form-control input-text"
              placeholder="Buscar por nombre"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          <div className="input-container">
            <select
              className="form-control input-select"
              value={selectedState}
              onChange={handleStateChange}
            >
              <option value="all">
                Estado: Todos {setSelectedState || "Selecciona"}
              </option>
              <option value="Aguascalientes">Aguascalientes</option>
              <option value="Baja California">Baja California</option>
              <option value="Baja California Sur">
                Baja California Sur
              </option>
              <option value="Campeche">Campeche</option>
              <option value="Chiapas">Chiapas</option>
              <option value="Chihuahua">Chihuahua</option>
              <option value="Ciudad de México">Ciudad de México</option>
              <option value="Coahuila">Coahuila</option>
              <option value="Colima">Colima</option>
              <option value="Durango">Durango</option>
              <option value="Estado de México">Estado de México</option>
              <option value="Guanajuato">Guanajuato</option>
              <option value="Guerrero">Guerrero</option>
              <option value="HHidalgoGO">Hidalgo</option>
              <option value="Jalisco">Jalisco</option>
              <option value="Michoacán">Michoacán</option>
              <option value="Morelos">Morelos</option>
              <option value="Nayarit">Nayarit</option>
              <option value="Nuevo León">Nuevo León</option>
              <option value="Oaxaca">Oaxaca</option>
              <option value="Puebla">Puebla</option>
              <option value="Querétaro">Querétaro</option>
              <option value="Quintana Roo">Quintana Roo</option>
              <option value="San Luis Potosí">San Luis Potosí</option>
              <option value="Sinaloa">Sinaloa</option>
              <option value="Sonora">Sonora</option>
              <option value="Tabasco">Tabasco</option>
              <option value="Tamaulipas">Tamaulipas</option>
              <option value="Tlaxcala">Tlaxcala</option>
              <option value="Veracruz">Veracruz</option>
              <option value="YYucatánUC">Yucatán</option>
              <option value="Zacatecas">Zacatecas</option>
              <option value="Universidad Nacional Autónoma de México">UNAM</option>
              <option value="Instituto Politécnico Nacional">IPN</option>
            </select>
            <div className="input-select-icon">
              <IoIosArrowDown />
            </div>
          </div>

          <div className="input-container">
            <div className="input-icon icon-search">
              <IoMdSearch />
            </div>
            <input
              type="text"
              className="form-control input-text"
              placeholder="Buscar por club"
              value={searchClub}
              onChange={handleSearchClubChange}
            />
          </div>

          <div className="input-container">
            <select
              className="form-control input-select"
              value={selectedMode}
              onChange={handleModeChange}
            >
              <option value="">
                Modalidad: Todas {setSelectedAsociation || "Selecciona"}
              </option>
              <option value="Recurvo">Recurvo</option>
              <option value="Compuesto">Compuesto</option>
              <option value="Barebow">Barebow</option>
            </select>
            <div className="input-select-icon">
              <IoIosArrowDown />
            </div>
          </div>

          <div className="input-container">
            <div className="reset-btn">
              <button type="button" onClick={handleInputsClear}>Borrar Filtros</button>
            </div>
          </div>
        </div>

        <section className="inputs_list-athletes">
          <div className="mt-4 contenedor-inputs-events">
            <div className="input-row">
            </div>
          </div>
        </section>

        <div className="athletes-container-cards">
          {filteredAthletes.length === 0 ? (
            <h4>No se encontraron Atletas</h4>
          ) : (
            filteredAthletes
              .slice(0, viewAthletes)
              .map((athlete, index) => (
                <AthletesTargetInfo key={index} dataAthlete={athlete} />
              ))
          )}
        </div>

        {filteredAthletes.length > viewAthletes && (
          <div className="show-more">
            <button onClick={handdleShowMoreAthletes}>Mostrar más</button>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
}

export default Athletes;
