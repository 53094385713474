import React from "react";
import "./error404.css";

import NavBar from "../../Components/navbar/navbar";
import Footer from "../../Components/footer/footer";
import DownButton from "../../Components/down-botton/down-button";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";
import { Link } from "react-router-dom";

const ErrorNoDisponible404 = () => {
  return (
    <>
      <NavBar />
      <ScrollToTop />
      <div className="notfound-hero">
        <h1>Página no encontrada</h1>
        <div className="buttons-container">
          <Link to="/">REGRESAR AL INICIO</Link>
        </div>
        
      </div>
      <Footer />
    </>
  );
};

export default ErrorNoDisponible404;
