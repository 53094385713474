import './records.css';
import { Helmet } from "react-helmet";
import NavBar from "../../Components/navbar/navbar";
import ScrollToTop from "../../Components/scrollToTop/scrollToTop";
import { IoIosArrowDown, IoMdSearch } from "react-icons/io";
import { useState } from 'react';
import Footer from '../../Components/footer/footer';

function Records() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDiscipline, setSelectedDiscipline] = useState("");
  const [selectedState, setSelectedState] = useState("all");

  const recordsData = [
    {
      category: "Compuesto Varonil",
      athlete: "OLVERA MÉNDEZ Rodrigo",
      state: 'SLP',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 705,
      plusX: 58,
      xSection: 32
    },
    {
      category: "Compuesto Femenil",
      athlete: "BERNAL SÁNCHEZ Mariana",
      state: 'JAL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 700,
      plusX: 52,
      xSection: 19
    },
    {
      category: "Recurvo Varonil",
      athlete: "PECH CHI Saul Jared",
      state: 'QROO',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 616,
      plusX: 13,
      xSection: 4
    },
    {
      category: "Recurvo Femenil",
      athlete: "ROMAN ARROYO Aida",
      state: 'PUE',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 639,
      plusX: 24,
      xSection: 7
    },
    {
      category: "Compuesto Sub 21 Varonil",
      athlete: "CUELLAR ITURBIDE Jóse Ángel",
      state: 'AGS',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 689,
      plusX: 45,
      xSection: 16
    },
    {
      category: "Compuesto Sub 21 Femenil",
      athlete: "MERRYMAN GONZÁLEZ Chelsea Nicole",
      state: 'JAL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 650,
      plusX: 21,
      xSection: 8
    },
    {
      category: "Recurvo Sub 21 Varonil",
      athlete: "RAMIREZ MORALES Osvaldo",
      state: 'JAL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 658,
      plusX: 22,
      xSection: 8
    },
    {
      category: "Recurvo Sub 21 Femenil",
      athlete: "PALACIOS REYES Ivana",
      state: 'HGO',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 584,
      plusX: 9,
      xSection: 1
    },
    {
      category: "Compuesto Sub 18 Varonil",
      athlete: "RÍOS RODRÍGUEZ Luis Esteban",
      state: 'YUC',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 689,
      plusX: 43,
      xSection: 15
    },
    {
      category: "Compuesto Sub 18 Femenil",
      athlete: "ARELLANO HERNÁNDEZ Karla Roxana",
      state: 'JAL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 660,
      plusX: 25,
      xSection: 10
    },
    {
      category: "Recurvo Sub 18 Varonil",
      athlete: "HERNÁNDEZ DE HOYOS Fernando Gabriel",
      state: 'NL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 629,
      plusX: 21,
      xSection: 6
    },
    {
      category: "Recurvo Sub 18 Femenil",
      athlete: "MONTOYA ALFARO Karime",
      state: 'CHIS',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 644,
      plusX: 25,
      xSection: 7
    },
    {
      category: "Compuesto Sub 16 Varonil",
      athlete: "LOZANO ROBLES Santiago",
      state: 'SLP',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 665,
      plusX: 29,
      xSection: 9
    },
    {
      category: "Compuesto Sub 16 Femenil",
      athlete: "ZAMARRÓN BECERRA Andrea Izel",
      state: 'JAL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 675,
      plusX: 38,
      xSection: 18
    },
    {
      category: "Recurvo Sub 16 Varonil",
      athlete: "RANGEL MORALES Axel Leonel",
      state: 'JAL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 657,
      plusX: 25,
      xSection: 7
    },
    {
      category: "Recurvo Sub 16 Femenil",
      athlete: "VELASCO MARISCAL Patricia Desirée",
      state: 'JAL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 656,
      plusX: 27,
      xSection: 9
    },
    {
      category: "Compuesto Sub 14 Varonil",
      athlete: "GALLEGOS OLLERVIDES Sebastián",
      state: 'TAMPS',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 670,
      plusX: 32,
      xSection: 15
    },
    {
      category: "Compuesto Sub 14 Femenil",
      athlete: "MARCOS Ana Sophie",
      state: 'SLP',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 674,
      plusX: 34,
      xSection: 12
    },
    {
      category: "Recurvo Sub 14 Varonil",
      athlete: "RAMOS MARTÍNEZ Jesús Caleb",
      state: 'NL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 670,
      plusX: 36,
      xSection: 13
    },
    {
      category: "Recurvo Sub 14 Femenil",
      athlete: "FLORES COBOS Monserrat",
      state: 'NL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 659,
      plusX: 25,
      xSection: 9
    },
    {
      category: "Compuesto Sub 12 Varonil",
      athlete: "OCHOA ALVARADO Cesar Alejandro",
      state: 'AGS',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 672,
      plusX: 31,
      xSection: 12
    },
    {
      category: "Compuesto Sub 12 Femenil",
      athlete: "SOLER PIÑÓN Sofia",
      state: 'SLP',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 708,
      plusX: 69,
      xSection: 40
    },
    {
      category: "Recurvo Sub 12 Varonil",
      athlete: "VIZUET LOZANO Diego",
      state: 'CDMX',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 620,
      plusX: 21,
      xSection: 8
    },
    {
      category: "Recurvo Sub 12 Femenil",
      athlete: "LARRAGUIVEL MEJIA Evelyn Fhernanda",
      state: 'CDMX',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 636,
      plusX: 21,
      xSection: 9
    },
    {
      category: "Compuesto 50+ Varonil",
      athlete: "LÓPEZ RODRÍGUEZ Luis Manuel",
      state: 'CDMX',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 672,
      plusX: 32,
      xSection: 9
    },
    {
      category: "Compuesto 50+ Femenil",
      athlete: "",
      state: '',
      competence: "",
      punctuation: "",
      plusX: "",
      xSection: ""
    },
    {
      category: "Recurvo 50+ Varonil",
      athlete: "SAMPERIO RIVERA Víctor Máximo",
      state: 'HGO',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 569,
      plusX: 10,
      xSection: 0
    },
    {
      category: "Recurvo 50+ Femenil",
      athlete: "",
      state: '',
      competence: "",
      punctuation: "",
      plusX: "",
      xSection: ""
    },
    {
      category: "Barebow Varonil",
      athlete: "LEAL RODRÍGUEZ Manuel Fabian",
      state: 'NL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 564,
      plusX: 5,
      xSection: 3
    },
    {
      category: "Barebow Femenil",
      athlete: "VÁZQUEZ ROBLES Nancy Esmeralda",
      state: 'BC',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 454,
      plusX: 10,
      xSection: 0
    },
    {
      category: "Barebow Sub 12 Varonil",
      athlete: "SERRANO GUTIÉRREZ Adrián",
      state: 'CHIS',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 417,
      plusX: 1,
      xSection: 1
    },
    {
      category: "Barebow Sub 12 Femenil",
      athlete: "AMBRIZ AGUILAR Engracia Antonella",
      state: 'CHIS',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 232,
      plusX: 1,
      xSection: 0
    },
    {
      category: "Barebow 50+ Varonil",
      athlete: "DUBIN TORRES Alberto",
      state: 'JAL',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 455,
      plusX: 3,
      xSection: 2
    },
    {
      category: "Barebow 50+ Femenil",
      athlete: "",
      state: '',
      competence: "",
      punctuation: "",
      plusX: "",
      xSection: ""
    },
    {
      category: "W1 Open Varonil",
      athlete: "SOLÍS MACÍAS VALADEZ Jaime",
      state: 'UNAM',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 355,
      plusX: 3,
      xSection: 1
    },
    {
      category: "W1 Open Femenil",
      athlete: "",
      state: '',
      competence: "",
      punctuation: "",
      plusX: "",
      xSection: ""
    },
    {
      category: "Visually Impaired 2/3",
      athlete: "RUIZ RUIZ Misael",
      state: 'UNAM',
      competence: "World Archery Mexico Championships 2024",
      punctuation: 425,
      plusX: 7,
      xSection: 1
    }
  ]

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };
  
  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const handleScroll = (e) => {
    e.preventDefault();
    const section = document.querySelector(".ourAthletes");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const filteredRecords = recordsData.filter(record => 
    `${record.athlete}`.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) && 
    (selectedCategory === "" || record.category === selectedCategory) && 
    (selectedState === "all" || record.state === selectedState)
  );

  const handleInputsClear = () => {
    setSearchTerm("");
    setSelectedCategory("")
    setSelectedState('all');
  }

  return(
    <>
      <ScrollToTop />
      <Helmet>
        <title>Records | WA México</title>
        <meta name="description" content="Conoce los records establecidos en los campeonatos nacionales de World Archery México." />
      </Helmet>
      <NavBar />
      <section className="contRecords">
        <div className="athletes-title">
          <h1>Records</h1>
        </div>

        <div className="contentParrafoAthletes">
          <p>
            Conoce los records establecidos en los campeonatos nacionales de World Archery México  
          </p>
        </div>

        <div>
          <a
            href="#"
            className="scroll-down"
            address="true"
            onClick={handleScroll}
          >
            <IoIosArrowDown className="scroll-down-arrow" />
          </a>
        </div>
      </section>

      <div className='container'>
        <div className="search-inputs-container mt-4">
          
          <div className="input-container">
            <div className="input-icon">
              <IoMdSearch />
            </div>
            <input
              type="text"
              className="form-control input-text"
              placeholder="Buscar por nombre"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          <div className="input-container">
            <select className="form-control input-select" value={selectedCategory} onChange={handleCategoryChange}>
              <option value="">Categoría: Todas {setSelectedDiscipline || "Selecciona"}</option>
              <option value="Compuesto Varonil">Compuesto Varonil</option>
              <option value="Compuesto Femenil">Compuesto Femenil</option>
              <option value="Compuesto Sub 21 Varonil">Compuesto Sub 21 Varonil</option>
              <option value="Compuesto Sub 21 Femenil">Compuesto Sub 21 Femenil</option>
              <option value="Compuesto Sub 18 Varonil">Compuesto Sub 18 Varonil</option>
              <option value="Compuesto Sub 18 Femenil">Compuesto Sub 18 Femenil</option>
              <option value="Compuesto Sub 16 Varonil">Compuesto Sub 16 Varonil</option>
              <option value="Compuesto Sub 16 Femenil">Compuesto Sub 16 Femenil</option>
              <option value="Compuesto Sub 14 Varonil">Compuesto Sub 14 Varonil</option>
              <option value="Compuesto Sub 14 Femenil">Compuesto Sub 14 Femenil</option>
              <option value="Compuesto Sub 12 Varonil">Compuesto Sub 12 Varonil</option>
              <option value="Compuesto Sub 12 Femenil">Compuesto Sub 12 Femenil</option>
              <option value="Compuesto 50+ Varonil">Compuesto 50+ Varonil</option>
              <option value="Compuesto 50+ Femenil">Compuesto 50+ Femenil</option>
              <option value="Recurvo Varonil">Recurvo Varonil</option>
              <option value="Recurvo Femenil">Recurvo Femenil</option>
              <option value="Recurvo Sub 21 Varonil">Recurvo Sub 21 Varonil</option>
              <option value="Recurvo Sub 21 Femenil">Recurvo Sub 21 Femenil</option>
              <option value="Recurvo Sub 18 Varonil">Recurvo Sub 18 Varonil</option>
              <option value="Recurvo Sub 18 Femenil">Recurvo Sub 18 Femenil</option>
              <option value="Recurvo Sub 16 Varonil">Recurvo Sub 16 Varonil</option>
              <option value="Recurvo Sub 16 Femenil">Recurvo Sub 16 Femenil</option>
              <option value="Recurvo Sub 14 Varonil">Recurvo Sub 14 Varonil</option>
              <option value="Recurvo Sub 14 Femenil">Recurvo Sub 14 Femenil</option>
              <option value="Recurvo Sub 12 Varonil">Recurvo Sub 12 Varonil</option>
              <option value="Recurvo Sub 12 Femenil">Recurvo Sub 12 Femenil</option>
              <option value="Recurvo 50+ Varonil">Recurvo 50+ Varonil</option>
              <option value="Recurvo 50+ Femenil">Recurvo 50+ Femenil</option>
              <option value="Barebow Varonil">Barebow Varonil</option>
              <option value="Barebow Femenil">Barebow Femenil</option>
              <option value="Barebow Sub 12 Varonil">Barebow Sub 12 Varonil</option>
              <option value="Barebow Sub 12 Femenil">Barebow Sub 12 Femenil</option>
              <option value="Barebow 50+ Varonil">Barebow 50+ Varonil</option>
              <option value="Barebow 50+ Femenil">Barebow 50+ Femenil</option>
              <option value="W1 Open Varonil">W1 Open Varonil</option>
              <option value="W1 Open Femenil">W1 Open Femenil</option>
              <option value="Visually Impaired 2/3">Visually Impaired 2/3</option>
            </select>
            <div className="input-select-icon">
              <IoIosArrowDown />
            </div>
          </div>

          <div className='input-container'>
            <select className="form-control input-select" value={selectedState} onChange={handleStateChange}>
              <option value="all">Estado: Todos {setSelectedState || "Selecciona"}</option>
              {/* <option value="all">Todos</option> */}
              <option value="AGS">Aguascalientes</option>
              <option value="BC">Baja California</option>
              <option value="BCS">Baja California Sur</option>
              <option value="CAM">Campeche</option>
              <option value="CHIS">Chiapas</option>
              <option value="CHIH">Chihuahua</option>
              <option value="CDMX">Ciudad de México</option>
              <option value="COAH">Coahuila</option>
              <option value="COL">Colima</option>
              <option value="DGO">Durango</option>
              <option value="EDOMEX">Estado de México</option>
              <option value="GTO">Guanajuato</option>
              <option value="GRO">Guerrero</option>
              <option value="HGO">Hidalgo</option>
              <option value="JAL">Jalisco</option>
              <option value="MICH">Michoacán</option>
              <option value="MOR">Morelos</option>
              <option value="NAY">Nayarit</option>
              <option value="NL">Nuevo León</option>
              <option value="OAX">Oaxaca</option>
              <option value="PUE">Puebla</option>
              <option value="QRO">Querétaro</option>
              <option value="QROO">Quintana Roo</option>
              <option value="SLP">San Luis Potosí</option>
              <option value="SIN">Sinaloa</option>
              <option value="SON">Sonora</option>
              <option value="TAB">Tabasco</option>
              <option value="TAMPS">Tamaulipas</option>
              <option value="TLAX">Tlaxcala</option>
              <option value="VER">Veracruz</option>
              <option value="YUC">Yucatán</option>
              <option value="ZAC">Zacatecas</option>
              <option value="UNAM">UNAM</option>
              <option value="IPN">IPN</option>
            </select>
            <div className="input-select-icon">
              <IoIosArrowDown />
            </div>
          </div>

          <div className="input-container">
            <div className="reset-btn">
              <button type="button" onClick={handleInputsClear}>Borrar Filtros</button>
            </div>
          </div>
        </div>
      </div>

      <section className='container'>
        <div className='container-records'>
          <div className='header-row'>
            <div className='category'>Categoría</div>
            <div className='athlete'>Atleta</div>
            <div className='state'>Estado</div>
            <div className='competence'>Competencia</div>
            <div className='punctuation'>Puntuación</div>
            <div className='plus-x'>10+X</div>
            <div className='x-section'>X</div>
          </div>

          {filteredRecords.map((record, index) => (
            <div className='record-row' key={index}>
              <div className='category' style={{background: index % 2 === 0 ? '#00b5e6' : '#e5239d'}}>{record.category}</div>
              <div className='athlete'>{record.athlete}</div>
              <div className='state'>{record.state}</div>
              <div className='competence'>{record.competence}</div>
              <div className='punctuation'>{record.punctuation}</div>
              <div className='plus-x'>{record.plusX}</div>
              <div className='x-section'>{record.xSection}</div>
            </div>
          ))}
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Records;