import React, { useState } from "react";
import AcordionItem from "./AcordionItem";
import "./style.css";

const Acordion = ({ key, dataEvents }) => {

  return (
    <>
      <div className="accordion">
          <AcordionItem
            type_event={dataEvents.type_event}
            modalities={dataEvents.level_event}
            disciplines={dataEvents.discipline}
            key={key}
            logo={dataEvents.logo} 
            title={dataEvents.name}
            imgSrc={dataEvents.state.image || ''} 
            state_code={dataEvents.state.name}
            date={`${new Date(dataEvents.date_start + "T00:00:00").toLocaleDateString()} - ${new Date(dataEvents.date_finish + "T00:00:00").toLocaleDateString()}`}
            inscription_date={dataEvents.inscription_date}
            field_name={dataEvents.field_name}
            link_address={dataEvents.link_address}
            categories={dataEvents.competition_type}
            documentacion={dataEvents.announcement}
            results={dataEvents.results}
            registration={dataEvents.registration}
          />
      </div>
    </>
  );
};

export default Acordion;
