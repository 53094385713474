import { useEffect } from "react";
import "./contact-form.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ContactForm() {
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const phoneValue = watch("phone");
  const cityValue = watch("city");

  useEffect(() => {
    if (phoneValue) {
      const onlyNumbers = phoneValue.replace(/\D/g, "");
      if (phoneValue !== onlyNumbers) {
        setValue("phone", onlyNumbers);
      }
    }
  });

  const handleContactSubmit = async (data) => {
    try {
      const params = {
        "name": data.name,
        "last_name": data.lastName,
        "phone": data.phone,
        "email": data.email,
        "message": data.message,
        "state": data.city
      }
      const response = await axios.post(`${process.env.REACT_APP_URL_BACK}/0a2b15b1a0d8d09e86d4b42d2012a091/contact`, null, {
        params: params,
      });

      if (response.data.code === 1) {
        navigate(`/thankyou`, {
          state: { statusThanks: "true" },
        });
      }
    } catch (error) {
      if (error.response) {
        console.error("Error en la respuesta del servidor:", error.response.data);
      }
    }
  };

  return (
    <form
      className="form-contact"
      autoComplete="off"
      onSubmit={handleSubmit(handleContactSubmit)}
    >
      <div className="group-input">
        <label htmlFor="name">Nombre(s)</label>
        <input
          type="text"
          placeholder="Tu(s) nombre(s)"
          id="name"
          {...register("name", {
            required: true,
          })}
        />
        {errors.name?.type === "required" && (
          <span className="alert-input-text">Campo requerido</span>
        )}
      </div>

      <div className="group-input">
        <label htmlFor="lastName">Apellidos</label>
        <input
          type="text"
          placeholder="Tu(s) apellido(s)"
          id="lastName"
          {...register("lastName", {
            required: true,
          })}
        />
        {errors.lastName?.type === "required" && (
          <span className="alert-input-text">Campo requerido</span>
        )}
      </div>

      <div className="group-input">
        <label htmlFor="phone">Teléfono</label>
        <input
          type="text"
          placeholder="Tu Teléfono"
          id="phone"
          {...register("phone", {
            required: true,
            minLength: 10,
            pattern: {
              value: /^[0-9]*$/,
              message: "Solo se permiten números",
            },
          })}
        />
        {errors.phone?.type === "required" && (
          <span className="alert-input-text">Campo requerido</span>
        )}
        {errors.phone?.type === "minLength" && (
          <span className="alert-input-text">
            Por favor introduce un télefono valido
          </span>
        )}
        {errors.phone?.type === "pattern" && (
          <span className="alert-input-text">Solo se permiten números</span>
        )}
      </div>
      <div className="group-input">
        <label htmlFor="email">Correo electrónico</label>
        <input
          type="text"
          placeholder="Tu correo electrónico"
          id="email"
          {...register("email", {
            required: true,
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "El email es invalido",
            },
          })}
        />
        {errors.email?.type === "required" && (
          <span className="alert-input-text">Campo requerido</span>
        )}
        {errors.email?.type === "pattern" && (
          <span className="alert-input-text">El email es invalido</span>
        )}
      </div>
      <div className="group-input">
        <label htmlFor="city">Estado</label>
        <select
          type="text"
          id="city"
          {...register("city", {
            pattern: {
              value: /^(?!no-option$).+$/,
              message: "Porfavor selecciona una opción",
            },
          })}
        >
          <option value={"no-option"}>Selecciona una opcion</option>
          <option value="Aguascalientes">Aguascalientes</option>
          <option value="Baja California">Baja California</option>
          <option value="Baja California Sur">Baja California Sur</option>
          <option value="Campeche">Campeche</option>
          <option value="Chiapas">Chiapas</option>
          <option value="Chihuahua">Chihuahua</option>
          <option value="Ciudad de México">Ciudad de México</option>
          <option value="Coahuila">Coahuila</option>
          <option value="Colima">Colima</option>
          <option value="Durango">Durango</option>
          <option value="Estado de México">Estado de México</option>
          <option value="Guanajuato">Guanajuato</option>
          <option value="Guerrero">Guerrero</option>
          <option value="Hidalgo">Hidalgo</option>
          <option value="Jalisco">Jalisco</option>
          <option value="Michoacán">Michoacán</option>
          <option value="Morelos">Morelos</option>
          <option value="Nayarit">Nayarit</option>
          <option value="Nuevo León">Nuevo León</option>
          <option value="Oaxaca">Oaxaca</option>
          <option value="Puebla">Puebla</option>
          <option value="Querétaro">Querétaro</option>
          <option value="Quintana Roo">Quintana Roo</option>
          <option value="San Luis Potosí">San Luis Potosí</option>
          <option value="Sinaloa">Sinaloa</option>
          <option value="Sonora">Sonora</option>
          <option value="Tabasco">Tabasco</option>
          <option value="Tamaulipas">Tamaulipas</option>
          <option value="Tlaxcala">Tlaxcala</option>
          <option value="Veracruz">Veracruz</option>
          <option value="Yucatán">Yucatán</option>
          <option value="Zacatecas">Zacatecas</option>
          <option value="UNAM">UNAM</option>
          <option value="IPN">IPN</option>
        </select>
        {errors.city?.type === "pattern" && (
          <span className="alert-input-text">Campo requerido</span>
        )}
      </div>
      <div className="group-input">
        <label htmlFor="message">Mensaje</label>

        <textarea 
          type="text"
          placeholder="Escribe aquí tu mensaje"
          id="message"
          {...register("message", {
            required: true,
          })} />
      </div>

      <button type="submit">Enviar</button>
    </form>
  );
}

export default ContactForm;
