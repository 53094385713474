import { Link } from 'react-router-dom';
import './square-card.css';

function SquareCard({img, text, link}) {
  return (
    <Link to={link}>
      <div className='square-card-container'>
        <div className='overlay'></div>
        <img className='img-background' src={img} alt={img} />
        <p>{text}</p>
      </div>
    </Link>
  )

}

export default SquareCard;