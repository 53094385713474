import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './styles.css';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import Dafne from '../../../assets/imgHome/national-team/Dafne.png'
import Maya from '../../../assets/imgHome/national-team/Maya.png'
import Angela from '../../../assets/imgHome/national-team/Angela.png'
import Alejandra from '../../../assets/imgHome/national-team/Alejandra.png'
import Mati from '../../../assets/imgHome/national-team/Mati.png'
import Sebas from '../../../assets/imgHome/national-team/Sebas.png'


const NationalTeamSlider = () => {
  return (
    <Swiper 
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={'auto'}
      loop={true}
      coverflowEffect={{
        rotate: 5, // Ángulo de rotación para un efecto más sutil
        stretch: 50, // Espaciado entre los slides
        depth: 400, // Aumentar la profundidad para un efecto más pronunciado
        modifier: 0.5, // Intensidad del efecto
        slideShadows: true, // Mantiene las sombras de los slides
      }}
      navigation={true}
      modules={[EffectCoverflow, Pagination, Navigation]}
      className="mySwiper"
      style={{ perspective: '1000px' }} // Agrega perspectiva al slider
    >
      <SwiperSlide>
        <img src={Dafne} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Maya} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Angela} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Alejandra} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Mati} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Sebas} />
      </SwiperSlide>
    </Swiper>
  );
};

export default NationalTeamSlider;